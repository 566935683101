// Generated by Framer (d19f530)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xf6tY8m7Z", "mDf7T0Lry", "LKOpIqtI3"];

const variantClassNames = {LKOpIqtI3: "framer-v-ttpggl", mDf7T0Lry: "framer-v-mykb7r", xf6tY8m7Z: "framer-v-kp8qtq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "xf6tY8m7Z", Mobile: "LKOpIqtI3", Tablet: "mDf7T0Lry"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; visible?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "xf6tY8m7Z", visible: aHH9fKCuM = true, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xf6tY8m7Z", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-VzmUS", classNames)} style={{display: "contents"}}>
{aHH9fKCuM && (<motion.div {...restProps} className={cx("framer-kp8qtq", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"xf6tY8m7Z"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({LKOpIqtI3: {"data-framer-name": "Mobile"}, mDf7T0Lry: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}/>)}
</motion.div>
</LayoutGroup>)

});

const css = [".framer-VzmUS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VzmUS .framer-x4stqd { display: block; }", ".framer-VzmUS .framer-kp8qtq { height: 40px; position: relative; width: 40px; }", ".framer-VzmUS.framer-v-mykb7r .framer-kp8qtq { height: 32px; width: 32px; }", ".framer-VzmUS.framer-v-ttpggl .framer-kp8qtq { height: 20px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mDf7T0Lry":{"layout":["fixed","fixed"]},"LKOpIqtI3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"aHH9fKCuM":"visible"}
 */
const FramerxhuBNhAbu: React.ComponentType<Props> = withCSS(Component, css, "framer-VzmUS") as typeof Component;
export default FramerxhuBNhAbu;

FramerxhuBNhAbu.displayName = "Elemental/ H padding";

FramerxhuBNhAbu.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerxhuBNhAbu, {variant: {options: ["xf6tY8m7Z", "mDf7T0Lry", "LKOpIqtI3"], optionTitles: ["Desktop", "Tablet", "Mobile"], title: "Variant", type: ControlType.Enum}, aHH9fKCuM: {defaultValue: true, title: "Visible", type: ControlType.Boolean}} as any)

addFonts(FramerxhuBNhAbu, [])